import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../styles/globals.scss';
import '../styles/home.scss';
import { StaticImage } from 'gatsby-plugin-image';
import SubscribeEmail from '../components/SubscribeEmail';

const IndexPage = () => {
  return (
    <>
      <main className="flex-center-column">
        <div className="page-view">
          <div className="landing-phone-container">
            <div className="landing-phone-sizing">
              <StaticImage
                className="landing-phone"
                src="../images/phone.png"
                alt="phone with logo"
              />
            </div>
          </div>
          <div className="landing-text-container">
            <h1>Engage with your audience.</h1>
            <p>Be heard from anywhere.</p>
          </div>
        </div>
        <div className="landing-info-container">
          <div className="page-view">
            <div className="landing-info-text-container">
              <h2>A microphone you carry with you...</h2>
              <p>Open Mike replaces audience microphones at your next event.</p>
              <p>
                Open Mike uses the microphone from your phone to broadcast your
                questions via the speakers at an event.
              </p>
              <p>
                This means that even virtual guests can ask their questions live
                during an event!
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/presenter.png"
                alt="conference audience"
              />
            </div>
          </div>
        </div>
        <div className="background-white">
          <div className="page-view-column-white">
            <div className="logo-sizing">
              <StaticImage
                src="../images/open-mike-logo.png"
                alt="open mike logo"
              />
            </div>
            <h2 className="subscribe-heading">Subscribe for updates</h2>
            <div className="paragraph-black">
              <p>
                If you would like to keep up to date with our progress, please
                subscribe below:
              </p>
            </div>
            <SubscribeEmail />
          </div>
        </div>
      </main>

      <footer></footer>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Home - Open Mike</title>
    <meta name="description" content="Open Mike" />
  </>
);
