import React, { useEffect, useState } from 'react';
import { Button, TextField, ThemeProvider } from '@mui/material';
import { sendEmail } from '../api/email';
import { theme } from '../styles/material-ui.theme';
import '../styles/subscribe-email.scss';

enum EmailStatus {
  AWAITING_SUBMISSION,
  SUCCESS,
  ERROR,
}

const SubscribeEmail = (): JSX.Element => {
  const defaultState = {
    emailStatus: EmailStatus.AWAITING_SUBMISSION,
    subscriberEmail: '',
  };

  const [emailStatus, setEmailStatus] = useState(defaultState.emailStatus);
  const [subscriberEmail, setSubscriberEmail] = useState(
    defaultState.subscriberEmail
  );

  useEffect(() => {
    if (emailStatus !== EmailStatus.AWAITING_SUBMISSION) {
      setTimeout(() => {
        setSubscriberEmail(defaultState.subscriberEmail);
        setEmailStatus(defaultState.emailStatus);
      }, 3000);
    }
  }, [defaultState.emailStatus, defaultState.subscriberEmail, emailStatus]);

  const addEmailToSubscribed = async () => {
    try {
      await sendEmail(subscriberEmail);
      setEmailStatus(EmailStatus.SUCCESS);
    } catch {
      setEmailStatus(EmailStatus.ERROR);
    }
  };

  const getEmailInputHelperText = () => {
    switch (emailStatus) {
      case EmailStatus.SUCCESS:
        return 'Thanks for subscribing! 🙂';
      case EmailStatus.ERROR:
        return 'Something went wrong... Please try again';
      default:
        return 'Please enter your email';
    }
  };

  return (
    <div className="home-email-container">
      <ThemeProvider theme={theme}>
        <div className="email-input">
          <TextField
            className="email-input"
            helperText={getEmailInputHelperText()}
            label="Email"
            onChange={({ target }) => setSubscriberEmail(target.value)}
            value={subscriberEmail}
          />
        </div>
        <div className="email-submit-button">
          <Button variant="contained" onClick={addEmailToSubscribed}>
            Keep me posted!
          </Button>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default SubscribeEmail;
